import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// MUI components
import {
    Add,
    Delete,
    Edit,
    Home
} from '@mui/icons-material';
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { blue, grey } from '@mui/material/colors';
import { emphasize, styled } from '@mui/material/styles';

// Components
import { Icon } from '@iconify/react';
import Preview from '../../../layout/Preview';

// Services
import ApiCalls from "../../../services/try";

// Externals
import swal from 'sweetalert';

//-------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800],
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
        backgroundColor: emphasize(theme.palette.grey[100], 0.06),
    },
    '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(theme.palette.grey[100], 0.12),
    },
}));

//-------------------------------------------------------------------------------

const KeySettings = () => {
    const [keys, setKeys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [newApiKey, setNewApiKey] = useState('');
    const [apiKeyError, setApiKeyError] = useState('');
    const [updateApiKeyError, setUpdateApiKeyError] = useState('');

    const [selectedKey, setSelectedKey] = useState({
        apiKey: '',
        usageCount: '',
        retryCount: ''
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        fetchKeys();
    }, []);

    const fetchKeys = async () => {
        setLoading(true);
        try {
            const response = await ApiCalls.getdetails('keys');
            setKeys(response.data);
        } catch (error) {
            console.error('Failed to fetch keys', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleKeyStatus = async (id) => {
        try {
            await ApiCalls.patch(`keys/${id}/toggle`, {});
            fetchKeys();
        } catch (error) {
            console.error('Failed to toggle key status', error);
        }
    };

    const isValidApiKey = (key) => key.trim().length > 9;

    const addNewKey = async () => {
        if (!newApiKey.trim()) {
            setApiKeyError("API Key cannot be empty.");
            return;
        }
        if (!isValidApiKey(newApiKey)) {
            setApiKeyError("API Key must be at least 10 characters long.");
            return;
        }

        setApiKeyError(''); // Clear error if validation passes
        const request = {
            apiKey: newApiKey,
            isActive: true,
            usageCount: 0,
            retryCount: 0,
            isEmailSent: false
        };

        try {
            await ApiCalls.register('keys', request);
            swal("Success", "Key created successfully!", "success");
            setNewApiKey('');
            fetchKeys();
            setOpenDialog(false);
        } catch (error) {
            console.error('Failed to add new key', error);

            // Check if the error is due to key conflict
            if (error.response?.status === 409) {
                swal({
                    title: "Error",
                    text: "The API key already exists.",
                    icon: "warning",
                    buttons: true,
                    content: {
                        element: "div",
                        attributes: {
                            style: "width: 300px; font-size: 14px; text-align: center;" // Inline styles
                        },
                    },
                });

            } else {
                swal("Error", "Failed to add new key. Please try again.", "error");
            }
        }
    };

    const handleEditClick = async (id) => {
        try {
            const response = await ApiCalls.getdetails(`keys/${id}`);
            setSelectedKey(response.data);
            setOpenUpdateDialog(true);
        } catch (error) {
            console.error('Failed to fetch key details', error);
        }
    };

    const updateKeyDetails = async () => {
        if (!selectedKey.apiKey.trim()) {
            setUpdateApiKeyError("API Key cannot be empty.");
            return;
        }
        if (!isValidApiKey(selectedKey.apiKey)) {
            setUpdateApiKeyError("API Key must be at least 10 characters long.");
            return;
        }

        setUpdateApiKeyError(''); // Clear error if validation passes
        try {
            await ApiCalls.edit(`keys/${selectedKey.id}`, selectedKey);
            swal("Updated", "Key updated successfully!", "success");
            setOpenUpdateDialog(false);
            fetchKeys();
        } catch (error) {
            console.error('Failed to update key', error);
            swal("Error", "Failed to update key", "error");
        }
    };


    const deleteKey = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this key!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    await ApiCalls.delete(`keys/${id}`);
                    swal("Deleted", "Key deleted successfully", "success");
                    fetchKeys();
                } catch (error) {
                    console.error('Failed to delete key', error);
                    swal("Error", "Failed to delete key", "error");
                }
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setNewApiKey('');
        setApiKeyError('');
    };

    const handleUpdateDialogClose = () => {
        setOpenUpdateDialog(false)
        setUpdateApiKeyError('');
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredKeys = searchTerm
        ? keys.filter((key) =>
            key.apiKey.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : keys;

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />}>
                                Home
                            </Button>
                        </Link>
                        <Link to="/settings">
                            <Button variant="text" startIcon={<Icon icon="eva:settings-2-fill" fontSize="small" />}>
                                Settings
                            </Button>
                        </Link>
                        <StyledBreadcrumb label="Key Management" />
                    </Breadcrumbs>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onMouseDown={handleMouseDown}
                        onClick={() => setOpenDialog(true)}
                    >
                        Add New Key
                    </Button>
                </Stack>

                {/* Add Key Dialog */}
                <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
                    <DialogTitle>Add New API Key</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="API Key"
                            value={newApiKey}
                            onChange={(e) => {
                                const sanitizedValue = e.target.value.replace(/\s+/g, ''); // Remove all white spaces, tabs, etc.
                                setNewApiKey(sanitizedValue);
                                setApiKeyError(''); // Clear error while typing
                            }}
                            sx={{
                                mt: 2
                            }}
                            multiline
                            rows={4}
                            fullWidth
                            error={!!apiKeyError}
                            helperText={apiKeyError} // Display the error message
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onMouseDown={handleMouseDown} onClick={handleDialogClose} color="error">Cancel</Button>
                        <Button onMouseDown={handleMouseDown} onClick={addNewKey} color="primary">Submit</Button>
                    </DialogActions>
                </Dialog>

                {/* Update Key Dialog */}
                <Dialog open={openUpdateDialog} onClose={handleUpdateDialogClose} fullWidth maxWidth="sm">
                    <DialogTitle>Update API Key</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="API Key"
                            value={selectedKey.apiKey}
                            onChange={(e) => {
                                setSelectedKey({ ...selectedKey, apiKey: e.target.value });
                                setUpdateApiKeyError(''); // Clear error while typing
                            }}
                            multiline
                            rows={4}
                            fullWidth
                            error={!!updateApiKeyError}
                            helperText={updateApiKeyError} // Display the error message
                            sx={{
                                marginTop: 2,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onMouseDown={handleMouseDown} onClick={handleUpdateDialogClose} variant="outlined" color="error">Cancel</Button>
                        <Button onMouseDown={handleMouseDown} onClick={updateKeyDetails} variant="outlined" color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                {/* API Key Table */}
                <Card>
                    <CardHeader
                        title={<Typography variant="h4">API Key Management</Typography>}
                        action={
                            <TextField
                                label="Search Key"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {loading ? (
                            <center><CircularProgress /></center>
                        ) : filteredKeys.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap', textAlign: 'center' }}>API Key</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Description</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Usage Count</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Retry Count</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Status</TableCell>
                                            <TableCell sx={{ backgroundColor: blue[900], color: 'white', whiteSpace: 'nowrap' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((key) => (
                                            <TableRow key={key.id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f4f4f4' } }}>
                                                <TableCell sx={{ textAlign: 'center' }}>{key.apiKey}</TableCell>
                                                <TableCell sx={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 550 }}>
                                                    {key.description}
                                                </TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{key.usageCount || 0}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{key.retryCount || 0}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={key.isActive ? "Active" : "Inactive"}>
                                                        <Switch
                                                            checked={key.isActive} // Assuming `isActive` is a boolean field
                                                            onChange={() => toggleKeyStatus(key.id, key.isActive)}
                                                            color="primary"
                                                            sx={{
                                                                '& .MuiSwitch-thumb': {
                                                                    color: key.isActive ? 'green' : 'red',
                                                                },
                                                                '& .MuiSwitch-track': {
                                                                    bgcolor: key.isActive ? 'green' : 'red',
                                                                },
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    <Tooltip title={key.isRestricted ? "PAID keys cannot be modified" : ""}>
                                                        <IconButton disabled={key.isRestricted} color="primary" size="small" onMouseDown={handleMouseDown} onClick={() => handleEditClick(key.id)}>
                                                            <Edit fontSize="small" />
                                                        </IconButton>
                                                        <IconButton disabled={key.isRestricted} color="error" size="small" onMouseDown={handleMouseDown} onClick={() => deleteKey(key.id)}>
                                                            <Delete fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filteredKeys.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                        '& .MuiTablePagination-toolbar': {
                                            alignItems: 'center',
                                        },
                                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'black', // Adjust this to your desired text color
                                        },
                                        '& .MuiTablePagination-select': {
                                            color: blue[700], // Adjust this to your desired text color
                                        },
                                        '& .MuiButtonBase-root': {
                                            outline: 'none',
                                            '&:focus': {
                                                outline: 'none',
                                                boxShadow: 'none',
                                            },
                                        },
                                        // Add these new styles for the icons
                                        '& .MuiSvgIcon-root': {
                                            color: blue[700], // Use a color that contrasts with your background
                                        },
                                        '& .Mui-disabled .MuiSvgIcon-root': {
                                            color: grey[400], // Use a lighter color for disabled state
                                        },
                                    }}
                                />
                            </TableContainer>
                        ) : (
                            <Typography>No keys available</Typography>
                        )}
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
};

export default KeySettings;
