import React, { useEffect, useRef, useState } from 'react';
import ShowLiveImage from './ShowLiveImage';

const VentilatorWeb = ({ deviceid }) => {
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [currentImage, setCurrentImage] = useState('');
    const [nextImage, setNextImage] = useState('');
    const [contentType, setContentType] = useState('');
    const [reconnect, setReconnect] = useState(false);
    const wsRef = useRef(null);

    // Ventilator Params
    const [tidalVolume, setTidalVolume] = useState({ value: 0, unit: '' });
    const [peep, setPeep] = useState({ value: 0, unit: '' });
    const [breathRate, setBreathRate] = useState({ value: 0, unit: '' });
    const [inspTime, setInspTime] = useState({ value: 0, unit: '' });
    const [fio2, setFio2] = useState({ value: 0, unit: '' });
    const [PIP, setPIP] = useState({ value: 0, unit: '' });
    const [VTE, setVTE] = useState({ value: 0, unit: '' });
    const [RR, setRR] = useState({ value: 0, unit: '' });
    const [minVent, setMinVent] = useState({ value: 0, unit: '' });

    useEffect(() => {
        console.log('Ventilator component mounted'); // Log to check mounting
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const ws = (wsRef.current = new WebSocket("wss://itouch-health.iorbit.health:8081/consumer"));
        let timerId;



        ws.onopen = () => {
            console.log('WebSocket connection opened', deviceid);
            ws.send(
                JSON.stringify({
                    PacketID: "WS_DEVICE_CONSUME_REQ",
                    DeviceUUID: deviceid,
                    ParamUUID: '10004',
                })
            );

            timerId = setTimeout(() => {
                ws.send(
                    JSON.stringify({
                        PacketID: "WS_DEVICE_FAST_SAMPLE_REQ",
                        DeviceUUID: deviceid,
                    })
                );
            }, 20);
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onmessage = (e) => {
            const message = JSON.parse(e.data);
            console.log(message, "Web Socket message");
            processImage(message);
        };

        ws.onclose = () => {
            console.log("WebSocket closed, attempting to reconnect in 2 seconds...");
            setReconnect(true);
        };

        return () => {
            ws.close();
            clearTimeout(timerId);
        };
    }, [deviceid]);

    useEffect(() => {
        if (reconnect) {
            const reconnectTimeout = setTimeout(() => {
                console.log("Reconnecting WebSocket...");
                setReconnect(false);
            }, 2000);

            return () => clearTimeout(reconnectTimeout);
        }
    }, [reconnect]);

    const processImage = (message) => {
        try {
            const resourceType = message?.resourceType;
            switch (resourceType) {
                case "Observation":
                    processObservation(message);
                    break;
                case "DocumentReference":
                    handleFHIRDocumentReferenceResource(message);
                    break;
                default:
                    console.log("Ignoring unsupported FHIR resource type: " + resourceType);
                    break;
            }
        } catch (e) {
            console.log("Error processing Image packet: " + e.message);
        }
    };

    const handleFHIRDocumentReferenceResource = (message) => {
        const content = message?.content?.[0];
        const attachment = content?.attachment;
        const base64Data = attachment?.data;
        const contentType = attachment?.contentType;

        console.log("Processing document reference:", message);
        console.log("Content Type:", contentType);
        console.log("Base64 Data Length:", base64Data ? base64Data.length : 'No Data');

        if (contentType && base64Data) {
            if (contentType.startsWith("image/")) { // Check if it's an image
                setContentType(contentType);
                setNextImage(base64Data);

                const currentImageElement = document.getElementById('currentImage');
                const nextImageElement = document.getElementById('nextImage');

                if (currentImageElement && nextImageElement) {
                    currentImageElement.style.opacity = 0;
                    nextImageElement.style.opacity = 1;

                    const fadeTimeout = setTimeout(() => {
                        setCurrentImage(base64Data); // Ensure this is valid
                        currentImageElement.style.opacity = 1;
                        nextImageElement.style.opacity = 0;
                    }, 300);

                    return () => clearTimeout(fadeTimeout);
                }
            } else {
                console.error("Unsupported content type:", contentType);
            }
        } else {
            console.error("Invalid image data received");
        }
    };


    const processObservation = (message) => {
        console.log("Ventilator message", message);
        if (message && message.valueQuantity && message.valueQuantity.value) {
            switch (message.code.coding[0].code) {
                case "68310-4":
                    setTidalVolume({ value: parseFloat(message.valueQuantity.value).toFixed(0), unit: message.valueQuantity.unit });
                    break;
                case "20078-8":
                    setPeep({ value: parseFloat(message.valueQuantity.value).toFixed(0), unit: message.valueQuantity.unit });
                    break;
                case "76276-7":
                    setBreathRate({ value: parseFloat(message.valueQuantity.value).toFixed(0), unit: message.valueQuantity.unit });
                    break;
                case "19886-0":
                    setInspTime({ value: parseFloat(message.valueQuantity.value).toFixed(0), unit: message.valueQuantity.unit });
                    break;
                case "3150-0":
                    setFio2({ value: parseFloat(message.valueQuantity.value).toFixed(0), unit: message.valueQuantity.unit });
                    break;
                case "20081-2":
                    setPIP({ value: parseFloat(message.valueQuantity.value).toFixed(1), unit: message.valueQuantity.unit });
                    break;
                case "68312-0":
                    setVTE({ value: parseFloat(message.valueQuantity.value).toFixed(0), unit: message.valueQuantity.unit });
                    break;
                case "9279-1":
                    setRR({ value: parseFloat(message.valueQuantity.value).toFixed(0), unit: message.valueQuantity.unit });
                    break;
                case "20112-5":
                    setMinVent({ value: parseFloat(message.valueQuantity.value).toFixed(1), unit: message.valueQuantity.unit });
                    break;
                default:
                    console.log("Unknown observation type:", message.code.text);
            }
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.parameters}>
                <ParameterItem label="Tidal Volume" value={tidalVolume.value} unit={tidalVolume.unit} />
                <ParameterItem label="PEEP" value={peep.value} unit={peep.unit} />
                <ParameterItem label="Breath Rate" value={breathRate.value} unit={breathRate.unit} />
                <ParameterItem label="Insp Time" value={inspTime.value} unit={inspTime.unit} />
                <ParameterItem label="FiO2" value={fio2.value} unit={fio2.unit} />
            </div>

            <div style={styles.imageDisplay}>
                <ShowLiveImage currentImage={currentImage} nextImage={nextImage} contentType={contentType} />
            </div>

            <div style={styles.parameters}>
                <ParameterItem label="PIP" value={PIP.value} unit={PIP.unit} />
                <ParameterItem label="VTE" value={VTE.value} unit={VTE.unit} />
                <ParameterItem label="RR" value={RR.value} unit={RR.unit} />
                <ParameterItem label="Min Vent" value={minVent.value} unit={minVent.unit} />
            </div>
        </div>
    );
};

const ParameterItem = ({ label, value, unit }) => {
    return (
        <div style={styles.parameterItem}>
            <p style={styles.paramLabel}>{label}:</p>
            <div style={styles.paramValue}>
                <span style={styles.value}>{value}</span>
                <span style={styles.unit}>{unit}</span>
            </div>
        </div>
    );
};

// Updated inline styles
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '120vh',
        width: '100vw',
        backgroundColor: '#0c1a2b',
        padding: '29px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
        color: '#ffffff',
        fontFamily: 'Arial, sans-serif',
    },
    parameters: {
        width: '20%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: '0 10px',
    },
    imageDisplay: {
        width: '130%',
        position: 'relative',
        overflow: 'hidden',
        border: '2px solid #ffffff',
        borderRadius: '8px',
    },
    parameterItem: {
        backgroundColor: '#1e2a3b',
        padding: '10px',
        borderRadius: '8px',
        margin: '10px 0',
    },
    paramLabel: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: 0,
    },
    paramValue: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    value: {
        fontSize: '24px',
    },
    unit: {
        fontSize: '16px',
        color: '#cccccc',
    },
};


export default VentilatorWeb;
